@import "../../variables.scss";

.Counterparty {
    width: 100%;
    form{
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .InputDadata {
        display: inline;
		position: relative;
       
        input {
            background: $background-white;
            border-radius: 5px;
            color: #272848;
            font-size: .875em;
            outline: 0;
            padding: 1.2em 1em 1.075em;
            margin-top: 0;
            transition: all 0.3s;
            width: 100%;
            border: 1px solid rgba(39, 40, 72, 0.2);
            height: auto;
            &::placeholder{
                color: rgba(39, 40, 72, 0.6);
                font-size: 0.855em;
            }
            &:focus {
                max-width: 100%;
                outline: none !important;
                border: 1px solid rgba(155, 155, 171, 0.3) !important;
                box-shadow: none;
            }
            &:focus+span {
                color: $label-color-focus;
                font-size: .785em;
                transform: translate(0, -4.6em)
            }
            &:valid+span {
                font-size: .785em;
                transform: translate(0, -4.6em)
            }
            &:disabled {
                opacity: .5;
                cursor: not-allowed;
            }
            &:disabled+span {
                cursor: not-allowed;
                opacity: .5;
                font-size: .785em;
                transform: translate(0, -4.6em)
            }
           
        }
      
        &.error input{
            border: 1px solid red;
        }
	}
    &__head{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 15px;

        @media (max-width: 767px) {
           flex-direction: column-reverse;
           align-items: flex-start;
           justify-content: flex-start;
        }
    }

    &__switcher {
        display: inline-flex;
        flex-wrap: wrap;
        gap: 10px;
    }

   

    &__notification{
        position: relative;
        border-radius: 10px;
        background-color: rgb(255 144 144 / 28%);
        padding: 7px 35px 7px 10px;

        > span{
            color:#ff3737bd;
            font-weight: 500;
        }

        .prompt {
            z-index: 10;
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            background: rgb(229 61 61 / 34%);
            align-items: center;
            border-radius: 50%;
            cursor: pointer;


            &:hover {
                    background: #f32020ad;
                }
                
            .prompt__info {
                background: $background-white;
                width: 20.875rem;
                top: 2.5rem;
                right: -10px;
                box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
                margin-left: auto;
                margin-right: auto;
                border-radius: 10px;
                
                p {
                    margin-bottom: 0;
                }
            }
        }

       

        &.success{
            background-color: rgb(109 113 249/ 20%);
            > span{
                color: #6D71F9;
            }

            .prompt{
                background: rgb(109 113 249/ 10%) ;

                &:hover {
                    background: rgb(109 113 249/ 34%) ;
                }
            }
        }
    }
    .react-tel-input {
        font-size: inherit;
        .form-control {
            width: 100%;
            background: $white;
            border: 1px solid transparent;
            border-radius: 5px;
            border: 1px solid rgba(155, 155, 171, 0.3);
            font-size: .875em;
            outline: 0;
            padding: 1.2em 1em 1.075em 70px;
            height: auto;
            &:focus {
                box-shadow: none;
            }
            &.invalid-number {
                border: 1px solid red;
            }
            &[disabled] {
                background: #E7E7EE;
            }

            &::placeholder{
                color: rgba(39, 40, 72, 0.6);
                font-size: 0.855em;
            }
        }
        .selected-flag {
            width: 62px;
            padding: 0 0 0 14px;
            &:before {
                content: none;
            }
            .arrow {
                left: 34px;
            }
        }
    }
}

.CounterpartyEntity{
    &__buttons{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap:25px;
        @media(max-width: 767px) {
            gap: 15px;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
        }
    }
}

.CounterpartyIndividual{
   
    .warning{
        background: #E9EAFE;
        border-radius: 10px;
    }
    .card{
        border: 1px solid rgba(155, 155, 171, 0.3);
        border-radius: 10px;
        background: $background-white;
        
        transition: all 0.3s;
        
        cursor: pointer ;
        width: 100%;
        .card__body{
           position: relative;
           padding: 1em;
        }
        .card__number{
            width: 100%;
            height: 100%;
            color: $label-color;
            font-size: 1em;
            outline: 0;
            margin-top: 0;
           
        }
        .card__label {
            display: block;
            color: $label-color;
            cursor: pointer ;
            font-size: 0.785em;
            line-height: 1em;
            transition: all 0.3s;
        }
       
      
        &.active{
            border: 1px solid $border-brandcolor;
            .main-method{
                display: block;
            }
        }

        .main-method{
            position: absolute;
            right: 1em;
            top: 1em;
            background-color: $button-light-background;
            border-radius: 10px;
            color: $link-color;
            padding: 0.5em;
            font-size: 0.85em;
            display: none;
           
        }


        button{
            padding: 0;
            background: none;
            display: flex;
            align-items: center;
            gap: 5px;
            color: #f13b3b;
            border: none;
            &:active{
                border: none;
            }

            &:focus{ 
                outline: none;
            }
        }
    }
}