.GeneralModal__Overlay {
	position: fixed;
	inset: 0px;
	background: rgba(26, 26, 26, 0.8);
	z-index: 99;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	.GeneralModal__Content {
		.GeneralModal {
			inset: 0px;
			border: none;
			background: rgb(255, 255, 255);
			overflow: auto;
			border-radius: 10px;
			outline: none;
		}
		.close-modal-button {
				position: absolute;
				top: 0;
				right: 0;
				background: transparent;
				border: 0;
				outline: 0;
			}
		.GeneralModal__Header {
			h2 {
				font-size: 2em;
				margin-bottom: 0;
			}

		}
		.GeneralModal__Body {
			h2 {
				font-weight: 500;
				font-size: 2.25em;
				color: #272848;
			}
			p {
				font-weight: normal;
				font-size: 1.125em;
				line-height: 139%;
				color: #9B9BAB;
			}
		}
	}
}