.PostCard {
	width: 100%;
	border-radius: 10px;
	background: #FFFFFF;
	.photo {
		width: 100%;
   		height: 15em;
		border-radius: 10px 10px 0 0;
	}
	h2 {
		font-weight: 500;
		font-size: 1.5em;
		color: #272848;
		margin-bottom: 0;
	}
}