/* COLORS */
$heading: #272848;

$text: #272848;

$white: #fff;

$link-heading: #272848;
$link-color: #6D71F9;
$link-hover: #0056b3;

$inscription-color: #9B9BAB;

$button-light-background: #E9EAFE;

$background-brandcolor: #6D71F9;
$background-dark: #272848;
$background-light: #EFF0F8;
$background-white: #fff;

$checked-input: #6D71F9;
$decoration-dgray: #E9EAFE;

$border-brandcolor: #6D71F9;
$border-gray: #9B9BAB;

$label-color: #9B9BAB;
$label-color-focus: #CCCCCC;