@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
body, html {
  font-family: 'Roboto';
  font-size: 14px;
  @media (min-width: 1200px) {
  	font-size: 16px;
  }
  @media (min-width: 1600px) {
  	font-size: 18px;
  }
}
#root {
	height: 100%;
}

input:not([type=checkbox]):not([type=radio]), select, textarea {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}



.main-button {
	display: inline-block;
	border: 1px solid #6D71F9;
	background-color: transparent;
	padding: .75em 3em;
	border-radius: 10px;
	font-weight: 500;
	font-size: 1em;
	color: #6D71F9;
	position: relative;
	&:hover {
    	filter: brightness(0.85);
    	text-decoration: none;
	}
	&:focus {
		outline: none;
		text-decoration: none;
	}
	&.link {
		border-radius: 5px;
		font-weight: 400;
	}
}
.secondary-button {
	display: inline-block;
	border: 1px solid #6D71F9;
	background-color: #6D71F9;
	padding: .75em 3em;
	border-radius: 10px;
	font-weight: 500;
	font-size: 1em;
	color: #FFFFFF;
	position: relative;
	&:hover {
    	filter: brightness(0.85);
    	text-decoration: none;
    	color: #FFFFFF;
	}
	&:focus {
		outline: none;
		text-decoration: none;
	}

	&:disabled {
		opacity: .5;
		filter: none;
		background-color: #6D71F9;
	}
}
.third-button {
	display: inline-block;
	border: 1px solid #C2C3DA;
	background-color: transparent;
	padding: .75em 1em;
	border-radius: 10px;
	font-weight: 500;
	font-size: 1em;
	color: #C2C3DA;
	position: relative;
	&:hover {
    	filter: brightness(0.8);
    	text-decoration: none;
	}
	&:focus {
		outline: none;
		text-decoration: none;
	}
}
.btn-edit {
	display: inline-block;
	border: 1px solid #EFF0F8;
	background-color: #EFF0F8;
	padding: .75em 3em;
	border-radius: 5px;
	font-weight: 400;
	font-size: 1em;
	color: #272848;
	position: relative;
	&:hover {
    	filter: brightness(0.8);
    	text-decoration: none;
	}
	&:focus {
		outline: none;
		text-decoration: none;
	}
}
.trash-button {
	display: inline-block;
	border: none;
	background-color: transparent;
	&:hover {
    	filter: brightness(0.8);
    	text-decoration: none;
	}
	&:focus {
		outline: none;
		text-decoration: none;
	}
}

.mini-loader {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    animation: typing 1s linear infinite alternate;
	position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: -21px;
    right: 0;
}

@keyframes typing{
	0%{
		 background-color: rgba(255,255,255, 1);
		 box-shadow: 12px 0px 0px 0px rgba(255,255,255,0.2), 
					 24px 0px 0px 0px rgba(255,255,255,0.2);
	   }
	 25%{ 
		 background-color: rgba(255,255,255, 0.4);
		 box-shadow: 12px 0px 0px 0px rgba(255,255,255,2), 
					 24px 0px 0px 0px rgba(255,255,255,0.2);
	 }
	 75%{ background-color: rgba(255,255,255, 0.4);
		 box-shadow: 12px 0px 0px 0px rgba(255,255,255,0.2), 
					 24px 0px 0px 0px rgba(255,255,255,1);
	   }
 }