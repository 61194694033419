.ReviewCard {
	width: 100%;
	border-radius: 10px;
	background: #FFFFFF;
	h2 {
		font-weight: 500;
		font-size: 1.125em;
		color: #272848;
		margin-bottom: 0;
	}
	span {
		font-weight: 400;
		font-size: .875em;
		color: #272848;
	}
	a {
		font-weight: 400;
		font-size: .875em;
		color: #6D71F9;
		text-decoration: none;
		&:hover {
			text-decoration: none;
		}
	}
	p {
		font-weight: 300;
		font-size: 14px;
		line-height: 150%;
		color: #272848;
		&:focus {
			outline: none;
		}
	}
}