.NewSeller {
	min-height: 100%;
	background: #EFF0F8;
	padding-bottom: 3em;
	@media (min-width: 768px) {
		margin-left: 100px;
	}
	.avatar {
		width: 8em;
		height: 8em;
		border-radius: 10px;
		position: relative;
		background-color: #E9EAFE;
	}
	h1 {
		font-weight: bold;
		font-size: 1.75em;
		color: #272848;
	}
	h2 {
		font-weight: 500;
		font-size: 1.5em;
		color: #272848;
		margin-bottom: 0;
	}
	h3 {
		font-weight: 400;
		font-size: 1.125em;
		color: #272848;
	}
	form {
		background: #fff;
		border-radius: 10px;
		padding-left: 2em;
		padding-right: 2em;
		@media (min-width: 1200px) {
			padding-left: 8em;
			padding-right: 8em;
		}
		.file {
			position: absolute;
			right: .5em;
			bottom: 0;
			width: 1.75em;
			height: 1.75em;
			display: flex;
			align-items: center;
			justify-content: center;
			background: #FFFFFF;
			border-radius: 5px;
			cursor: pointer;
			.file-input {
				position: absolute;
				z-index: -1;
				opacity: 0;
			}
			.file-input+img {
				display: inline-flex;
				align-items: center;
				user-select: none;
				cursor: pointer;
			}
		}
		.select_status {
			width: 100%;
			@media(min-width: 992px) {
				width: 20%;
			}
			.select-list {
				max-height: 7em;
			}
		}
		.holder-input-price {
			position: relative;
			display: inline;
			.input-price {
				background-color: #fff;
				border-radius: 5px;
				color: #272848;
				font-size: .875em;
				outline: 0;
				padding: 1.5em 1em .75em;
				margin-top: 0;
				transition: all 0.3s;
				width: 100%;
				border: 1px solid rgba(39, 40, 72, 0.2);
				&:focus {
					max-width: 100%;
				}
				&:focus+span {
					color: rgba(39, 40, 72, 0.6);
					font-size: .625em;
					transform: translateY(-4.3em);
				}
				&:valid+span {
					color: rgba(39, 40, 72, 0.6);
					font-size: .625em;
					transform: translateY(-4.3em);
				}
				&:disabled {
					opacity: .5;
					cursor: not-allowed;
				}
				&:disabled+span {
					color: rgba(39, 40, 72, 0.6);
					cursor: not-allowed;
					opacity: .5;
					font-size: .625em;
					transform: translateY(-4.3em);
				}
				&.error {
					background-color: #EFF0F8;
				}
			}
			span {
				position: absolute;
				display: block;
				color: #272848;
				cursor: text;
				font-size: .875em;
				line-height: 1em;
				transform: translateY(-2.4em);
				transition: all 0.3s;
				padding-left: 1.2em;
			}
		}
		.checkbox_holder {
			.checkbox {
				position: absolute;
				z-index: -1;
				opacity: 0;
			}
			span {
				font-weight: normal;
				font-size: 1em;
				color: rgba(39, 40, 72, 0.6);
			}
			.checkbox+span {
				display: inline-flex;
				align-items: center;
				user-select: none;
			}
			.checkbox+span::before {
				content: '';
				display: inline-block;
				width: 1.5em;
				height: 1.5em;
				flex-shrink: 0;
				flex-grow: 0;
				background: #EFF0F8;
				border: none;
				border-radius: 5px;
				margin-right: 1em;
				background-repeat: no-repeat;
				background-position: center center;
				background-size: 50% 50%;
				cursor: pointer;
			}
			.checkbox:checked+span::before {
				background-color: #6D71F9;
				background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
			}
		}
	}
}