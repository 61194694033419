@import "../../variables.scss";

.Radio {
	position: relative;
	cursor: pointer;
	margin-bottom: 0 !important;
	& input[type="radio"] {
		display: none;
		&:checked+.checkRadio:before{
			display: block;
		}
	}

	.checkRadio{
		width: 20px;
		height: 20px;
		position: relative;
		border-radius: 50%;
		border: 1px solid rgba(155, 155, 171, 0.3);
		display: flex;
		align-items: center;
		justify-content: center;
		&::before{
			content: "";
			position: absolute;
			display: none;
			left: 50%;
			top: 50%;
			width: 11px;
			height: 11px;
			transform: translate(-50%,-50%);
			background-color: #6D71F9;
			border-radius: 50%;
		}
		
	}

	span{
		color: $label-color;
		font-weight: normal;
		font-size: 1em;
	}
}