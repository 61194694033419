.Select {
	position: relative;
	margin: 0;
	.select-head {
		display: block;
		cursor: pointer;
		background-color: #fff;
		border: 1px solid transparent;
		border-radius: 5px;
		color: #272848;
		font-size: .875em;
		outline: 0;
		padding: 1.125em 1em;
		margin-top: 0;
		transition: all 0.3s;
		width: 100%;
		border: 1px solid rgba(39, 40, 72, 0.2);
		box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.03);
		img {
			position: absolute;
			right: 1.5em;
			top: 0;
			bottom: 0;
			margin: auto;
		}
		&.open {
			img {
				transform: rotate(180deg);
			}
		}
		&.error {
			background-color: #EFF0F8;
		}
	}
	.select-list {
		padding: 0;
		transition: all 0.3s;
		z-index: 2;
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		background: #fff;
		border-radius: 5px;
		max-height: 14em;
		overflow-x: hidden;
		overflow-y: auto;
		margin-top: .5em;
		border: 1px solid rgba(39, 40, 72, 0.2);
		box-shadow: 0 0 4px rgba(0,0,0,.05);
		&__item {
			position: relative;
			border-top: 1px solid rgba(39, 40, 72, 0.2);
			padding: 1em 1em;
			cursor: pointer;
			list-style-type: none;
			&:hover {
				background: rgba(0,0,0,.05);
			}
			&.no-active {
				cursor: not-allowed;
				opacity: .4;
			}
		}
	}
}