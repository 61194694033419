.FileInput {
    background: rgba(39, 40, 72, 0.2);
    border-radius: 5px;
    width: 100%;
    height: 100%;

    .file {
        position: absolute;
        right: 1.5em;
        bottom: 5px;
        width: 1.75em;
        height: 1.75em;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #FFFFFF;
        border-radius: 5px;
        cursor: pointer;

        .file-input {
            position: absolute;
            z-index: -1;
            opacity: 0;
        }

        .file-input+img {
            display: inline-flex;
            align-items: center;
            user-select: none;
            cursor: pointer;
        }
    }
}