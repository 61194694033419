@import "../../variables.scss";

.ContentModal__Overlay {
	position: fixed;
	inset: 0px;
	background: rgba(26, 26, 26, 0.8);
	z-index: 99;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	.ContentModal__Content {
		.ContentModal_wrapper {
			height: 100%;
			.ContentModal {
				inset: 0px;
				border: none;
				background: #fff;
				overflow: auto;
				border-radius: 10px;
				outline: none;
			}
		}
		
		.close-modal-button {
				position: absolute;
				top: 0;
				right: 0;
				background: transparent;
				border: 0;
				outline: 0;
			}
		.GeneralModal__Header {
			h2 {
				font-size: 2em;
				margin-bottom: 0;
			}

		}
	}
}