.CategoryHelpfulForm {
  border-bottom: 1px solid rgba(39, 40, 72, 0.2);
  .checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  span {
    font-weight: normal;
    font-size: .875em;
    color: rgba(39, 40, 72, 0.6);
  }
  .checkbox+span {
    display: inline-flex;
    align-items: center;
    user-select: none;
  }
  .checkbox+span::before {
    content: '';
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    flex-shrink: 0;
    flex-grow: 0;
    background: #EFF0F8;
    border: none;
    border-radius: 5px;
    margin-right: 1em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    cursor: pointer;
  }
  .checkbox:checked+span::before {
    background-color: #6D71F9;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
  }
}