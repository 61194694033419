*{
	margin: 0;
	padding-right: 0;
	box-sizing: border-box;
}
html, body {
	height: 100%;
	width: 100%;
}

.App {
	height: 100%;
}
