.Textarea {
	textarea {
		width: 100%;
		height: 15em;
		resize: none;
		border-radius: 5px;
		border: 1px solid rgba(39, 40, 72, 0.2);
		outline: none;
		padding: 1em;
		font-size: .875em;
		color: #272848;
	}
}