.Auth {
	min-height: 100%;
	background: #EFF0F8;
	h1 {
		font-weight: bold;
		font-size: 1.75em;
		color: #272848;
	}
	form {
		background: #fff;
		border-radius: 10px;
	}
}